import React, { Fragment } from 'react';
import ReactGA from 'react-ga';
import type { AppProps } from 'next/app';
import {
  CartProvider,
  CreatorUIProvider,
  ErrorBoundary,
  ErrorContext,
  FeatureFlagProvider,
  HistoryProvider,
  SEOProvider,
  DarkModeProvider,
  SocketProvider,
} from '@providers';
import AuthProvider from '@providers/Auth/AuthProvider';
import AppContextWrapper from '@context/RootContext';
import ErrorGate, { ErrorGateSSRProps } from '@context/ErrorContext';
import { BaseLayout, getNoLayout } from '@layouts';
import { NoodlePage } from '@typings/NextJS';

import { JobProvider } from '@providers/Jobs';
import { ToastProvider } from '@context/ToastContext';
import ToastContainer from '@components/Toasts';
import { SeoSSRProps } from '@providers/SEO';
import IframeAdjustments from '@providers/IframeAdjustments';
import FacebookPixelProvider from '@providers/FacebookPixel/Provider';
import MixpanelProvider from '@providers/Mixpanel/MixpanelProvider';
import MessagesProvider from '@providers/Messages/MessagesProvider';
import { FeatureBundle, LazyMotion } from 'framer-motion';
import PWAProvider from '@providers/PWA';
import TeamsProvider from '@providers/Teams/TeamsProvider';
import LocalStorageStateProvider from '@providers/LocalStorageState';
import AgencyProvider from '@providers/Agency/AgencyProvider';
import PushNotificationProvider from '@providers/PushNotifications';
import GDPRBanner from '@components/GDPRBanner';
import { Inter } from 'next/font/google';
import { TranslationProvider } from '@/providers/TranslationProvider';
import '@styles/globals.scss';

const font = Inter({
  display: 'swap',
  subsets: ['latin'],
  weight: ['400', '600'],
});

const loadFramerFeatures = (): Promise<FeatureBundle> => import('@helpers/getFramerMotionFeatures').then(res => res.default);

type ProviderProps = SeoSSRProps & ErrorGateSSRProps;

type NoodleAppProps = AppProps & {
  Component: NoodlePage;
};

if (typeof window !== 'undefined') {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || '');
  ReactGA.plugin.require('ec');
}

const NoodleApp: React.FC<NoodleAppProps> = ({ Component, pageProps }) => {
  const Layout: NonNullable<NoodlePage['Layout']> = Component.Layout || BaseLayout;
  const getLayout = Component.getLayout || getNoLayout;

  const { ssrError, seo, ...restProps } = pageProps as ProviderProps;

  return (
    <Fragment>
      <style jsx global>
        {`
          :root {
            --font-face-primary: ${font.style.fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
              'Open Sans', 'Helvetica Neue', sans-serif;
          }
        `}
      </style>
      <ErrorBoundary>
        <FacebookPixelProvider />
        <DarkModeProvider />
        <LazyMotion features={loadFramerFeatures}>
          <ToastProvider>
            <JobProvider>
              <SEOProvider seo={seo} />
              <AuthProvider>
                <AgencyProvider>
                  <TeamsProvider>
                    <LocalStorageStateProvider />
                    <FeatureFlagProvider>
                      <AppContextWrapper>
                        <ErrorGate ssrError={ssrError}>
                          <PWAProvider>
                            <PushNotificationProvider>
                              <IframeAdjustments />
                              <ErrorContext />
                              <MixpanelProvider />
                              <CartProvider />
                              {/* Only pass in the creatorSlug if also passing in creatorUI */}
                              <CreatorUIProvider creatorSlug={pageProps.creatorUI ? pageProps.creatorSlug : null} creatorUI={pageProps.creatorUI}>
                                <HistoryProvider>
                                  <SocketProvider>
                                    <MessagesProvider>
                                      <TranslationProvider>
                                        <Layout {...restProps}>
                                          {getLayout(<Component {...restProps} />)}
                                          <GDPRBanner />
                                        </Layout>
                                      </TranslationProvider>
                                    </MessagesProvider>
                                  </SocketProvider>
                                </HistoryProvider>
                              </CreatorUIProvider>
                            </PushNotificationProvider>
                          </PWAProvider>
                        </ErrorGate>
                      </AppContextWrapper>
                    </FeatureFlagProvider>
                  </TeamsProvider>
                </AgencyProvider>
              </AuthProvider>
              <ToastContainer />
            </JobProvider>
          </ToastProvider>
        </LazyMotion>
      </ErrorBoundary>
    </Fragment>
  );
};

export default NoodleApp;

// Not looking at results and causing errors sometimes.

// const canSendBeacon = (
//   typeof document !== 'undefined'
//   && configuration.ENABLE_WEB_VITALS
//   && window?.location && window.location.protocol === 'https:'
//   && navigator && navigator.sendBeacon
// );
// const sendBeacon = canSendBeacon ? navigator.sendBeacon.bind(navigator) : null;

// export function reportWebVitals(metric: NextWebVitalsMetric): void {
//   const body = JSON.stringify(metric);
//   const url = '/api/health-checks/web-vitals';

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
//   if (sendBeacon) {
//     try {
//       sendBeacon(url, body);
//     } catch (_err) {
//       /* noop */
//     }
//   } else if (fetch) {
//     fetch(url, { body, keepalive: true, method: 'POST' }).catch(() => {
//       /* noop */
//     });
//   }
// }
