import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports/{creditReportId}/pdf']['GET'];

type Input = ThisApi['params'];

const getCreditReportPDF = async ({ creditReportId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/credit-reports/${creditReportId}/pdf`, {
    responseType: 'blob',
  });

  return response.data;
};

export default getCreditReportPDF;
