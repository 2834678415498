import {
  useState, useMemo, useContext, useRef,
} from 'react';
import promiseRetry from 'promise-retry';
import Axios from 'axios';
import { ErrorContext } from '@context/ErrorContext';
import ApiError, { PageError } from '@helpers/ApiError';
import { AnyError, BaseFunction } from '@typings/utility';
import useToast, { AddToast, ToastTypeVariants } from '@hooks/useToast';
import { logError, ErrorFlow, SeverityLevel } from '@providers/ErrorTracking';
import { SystemHealthMonitor, TransactionNames } from '@providers/SystemHealth';
import { GetData } from './typings';

export type ToastErrorMessage = (error: ApiError) => Parameters<AddToast>; // eslint-disable-line import/no-unused-modules
export type ToastSuccessMessage = (response: unknown) => Parameters<AddToast>; // eslint-disable-line import/no-unused-modules

// anything from httpClient is wrapped in fetchingFactory which does the toast, logError, and setError
// anything from tsClient, does not
// handlePageRequest takes care of reporting errors during SSR data fetching, so do not need to do anything there.
// Errors should either be handled by useNoodleApi (CSR) or handlePageRequest (SSR) not in fetchingFactory.
type UseNoodleApiOptions<F extends BaseFunction = never> = {
  default?: Awaited<ReturnType<F>> | null;
  errorLevel?: SeverityLevel;
  errorFlow?: ErrorFlow;
  toastOnError?: boolean;
  toastErrorMessage?: ToastErrorMessage;
  toastSuccessMessage?: ToastSuccessMessage;
  reportError?: boolean;
  retryOptions?: {
    retries?: number;
    factor?: number;
    minTimeout?: number;
    maxTimeout?: number;
  } | null;
  showErrorScreen?: boolean;
  healthMonitor?: {
    name: TransactionNames;
  };
};

interface IFetchingState<F extends BaseFunction> {
  data: Awaited<ReturnType<F>> | null,
  error: PageError,
  originalError: AnyError | null,
  fetchingTime: null | number,
  isFetched: boolean,
  isFetching: boolean,
}

interface IUseNApi<F extends BaseFunction> {
  data: Awaited<ReturnType<F>> | null;
  error: PageError;
  fetchingState: {
    isFetching: boolean;
    isFetched: boolean;
    fetchingTime: number | null;
  };
  getData: GetData<F>;
}

const DEFAULT_RETRY_OPTIONS = {
  factor: 1,
  maxTimeout: 2000,
  minTimeout: 500,
  retries: 5,
};

const defaultErrorToastMessage: ToastErrorMessage = (error) => [
  ToastTypeVariants.ERROR,
  error.message,
];

const useNoodleApi = <F extends BaseFunction>(
  fetchingFn: F,
  {
    default: defaultValue = null,
    errorLevel,
    errorFlow,
    toastOnError = false,
    toastErrorMessage = defaultErrorToastMessage,
    toastSuccessMessage,
    healthMonitor: healthMonitorOptions,
    reportError = true,
    showErrorScreen = false,
    retryOptions = null,
  }: UseNoodleApiOptions<F> = {}): IUseNApi<F> => {
  const { setError } = useContext(ErrorContext);
  const addToast = useToast();
  const requestingPage = useRef('unknown page');

  const [fetchingState, setFetchingState] = useState<IFetchingState<F>>({
    data: defaultValue,
    error: null,
    fetchingTime: null,
    isFetched: false,
    isFetching: false,
    originalError: null,
  });

  const doRetry = Boolean(retryOptions);
  const retryRetries = retryOptions?.retries ?? DEFAULT_RETRY_OPTIONS.retries;
  const retryFactor = retryOptions?.factor ?? DEFAULT_RETRY_OPTIONS.factor;
  const retryMinTimeout = retryOptions?.minTimeout ?? DEFAULT_RETRY_OPTIONS.minTimeout;
  const retryMaxTimeout = retryOptions?.minTimeout ?? DEFAULT_RETRY_OPTIONS.maxTimeout;

  // Don't add defaultValue to these dependencies.
  // This would trigger the function to be recreated breaking anyone who does `useEffect(() => getData(), [getData])`.
  // If the developer did `defaultValue: []`, this changes on every render which would trigger the above on every render.
  // But it means that defaultValue can't change after the page is initialized.
  const getData: GetData<F> = useMemo(() => async (...args) => {
    setFetchingState((prevState) => ({
      ...prevState,
      data: defaultValue,
      error: null,
      isFetched: false,
      isFetching: true,
    }));

    requestingPage.current = window?.location?.href || 'unknown page';

    const systemHealthMonitor = healthMonitorOptions?.name ? new SystemHealthMonitor(healthMonitorOptions.name) : null;
    try {
      let response: ReturnType<F>;
      if (doRetry) {
        response = await promiseRetry(async (retryFn) => {
          try {
            const innerResponse = await fetchingFn(...args);
            return innerResponse;
          } catch (err) {
            return retryFn(err);
          }
        }, {
          factor: retryFactor,
          maxTimeout: retryMaxTimeout,
          minTimeout: retryMinTimeout,
          retries: retryRetries,
        });
      } else {
        response = await fetchingFn(...args);
      }
      setFetchingState((prevState) => ({
        ...prevState,
        data: response,
        error: null,
        fetchingTime: new Date().getDate(),
        isFetched: true,
        isFetching: false,
        originalError: null,
      }));
      if (toastSuccessMessage) {
        addToast(...toastSuccessMessage(response));
      }
      systemHealthMonitor?.finish();
      return { data: response, error: null };
    } catch (originalError) {
      const error = ApiError.create(originalError);

      // do error handling here instead of a useEffect so that the logError is inside the transaction for the systemHealthMonitor.
      if (reportError) {
        const errors = error?.errors || [];
        const errorDataObject = typeof error === 'string' ? { data: error } : error;
        const axiosErrorContext = fetchingState.originalError && Axios.isAxiosError(fetchingState.originalError)
          ? {
            baseUrl: fetchingState.originalError.config?.baseURL,
            method: fetchingState.originalError.config?.method,
            url: fetchingState.originalError.config?.url,
          }
          : {};
        const functionName = fetchingFn?.name || `${fetchingFn}`;
        const errorType = error?.type || 'UnknownError';
        const errorMessage = error?.message || 'UnknownError';
        const currentPage = window?.location?.href || 'unknown page';
        const tags = errorFlow ? undefined : { flow: errorFlow };
        logError(
          error,
          {
            currentPage,
            errorMessage,
            errorType,
            errors,
            functionName,
            handledBy: 'useNoodleApi',
            level: errorLevel,
            requestingPage: requestingPage.current,
            tags,
            ...errorDataObject,
            ...axiosErrorContext,
          },
        );
      }
      if (toastOnError) {
        addToast(...toastErrorMessage(error));
      }
      if (showErrorScreen) {
        setError(error);
      }

      systemHealthMonitor?.finish(error);

      setFetchingState((prevState) => ({
        ...prevState,
        error,
        isFetching: false,
        originalError,
      }));

      return { data: null, error };
    }
  }, [fetchingFn, doRetry, retryRetries, retryFactor, retryMinTimeout, retryMaxTimeout]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data: fetchingState.data,
    error: fetchingState.error,
    fetchingState,
    getData,
  };
};

export default useNoodleApi;
