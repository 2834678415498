// @todo make use of deserializers with defaultValue=
export const PACKAGE_NAME = process.env.NEXT_PUBLIC_PACKAGE_NAME;
export const VERSION = process.env.NEXT_PUBLIC_VERSION;
export const DEPLOYMENT_TIER = process.env.NEXT_PUBLIC_DEPLOYMENT_TIER;
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID || '';
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
export const GA4_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID;
export const STUBGROUP_GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_STUBGROUP_GA_MEASUREMENT_ID;
export const GOOGLE_OPTIMIZE_ID = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID;
export const LINKED_IN_PARTNER_ID = process.env.NEXT_PUBLIC_LINKED_IN_PARTNER_ID;
export const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || 'debug';
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
export const MIXPANEL_ENVIRONMENT = process.env.NEXT_PUBLIC_MIXPANEL_ENVIRONMENT;
export const NOODLE_API_HOST = process.env.NEXT_PUBLIC_NOODLE_API_HOST || 'http://localhost:3100';
export const NOODLE_MEDIA_HOST = process.env.NEXT_PUBLIC_NOODLE_MEDIA_HOST || 'http://localhost:3200';
export const NOODLE_MEDIA_UPLOAD_HOST = process.env.NEXT_PUBLIC_NOODLE_MEDIA_UPLOAD_HOST || 'http://localhost:3201';
export const NOODLE_DOCUMENT_HOST = process.env.NEXT_PUBLIC_NOODLE_DOCUMENT_HOST || 'http://localhost:3500';
export const NOODLE_DOCUMENT_UPLOAD_HOST = process.env.NEXT_PUBLIC_NOODLE_DOCUMENT_UPLOAD_HOST || 'http://localhost:3501';
export const SELF_ORIGIN = process.env.NEXT_PUBLIC_SELF_HOST || 'http://localhost:3000';
const selfUrl = new URL(SELF_ORIGIN);
export const SELF_HOST = selfUrl.host;
export const SENTRY_DEBUG = process.env.NEXT_PUBLIC_SENTRY_DEBUG;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED;
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
const MAX_FILE_SIZE_STRING = process.env.NEXT_PUBLIC_MAX_FILE_SIZE || '';
export const MAX_FILE_SIZE = MAX_FILE_SIZE_STRING ? Number.parseInt(MAX_FILE_SIZE_STRING, 10) : 0;
export const IP_INFO_TOKEN = process.env.NEXT_PUBLIC_IP_INFO_TOKEN;
export const FEATURE_MULTI_REGION_MEDIA = process.env.NEXT_PUBLIC_FEATURE_MULTI_REGION_MEDIA === 'true';
export const NEXT_PUBLIC_FEATURE_DRAFT_BROADCASTS_ENABLED = process.env.NEXT_PUBLIC_FEATURE_DRAFT_BROADCASTS_ENABLED === 'true';
export const FEATURE_FLAG_TRANSACTIONS = process.env.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS === 'true';
export const FEATURE_FLAG_TRANSACTIONS_PAYOUTS = process.env.NEXT_PUBLIC_FEATURE_FLAG_TRANSACTIONS_PAYOUTS === 'true';
export const FEATURE_FLAG_DASHBOARD_AI_CHAT = process.env.NEXT_PUBLIC_FEATURE_FLAG_DASHBOARD_AI_CHAT === 'true';
export const LOG_ROCKET_APP_ID = process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID;
export const ROUTABLE_DASHBOARD_URL = process.env.NEXT_PUBLIC_ROUTABLE_DASHBOARD_URL;
export const LINKEDIN_OPEN_NOODLE_SHOP_CONVERSION_ID = process.env.NEXT_PUBLIC_LINKEDIN_OPEN_NOODLE_SHOP_CONVERSION_ID;
export const NOODLE_STRIPE_ACCOUNT_ID = process.env.NEXT_PUBLIC_NOODLE_STRIPE_ACCOUNT_ID;
export const LD_CLIENT_ID = process.env.NEXT_PUBLIC_LD_CLIENT_ID || '';
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const JWT_COOKIE_NAME = process.env.NEXT_PUBLIC_JWT_COOKIE_NAME || 'u';
export const GOOGLE_OAUTH2_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_OAUTH2_CLIENT_ID;
export const GOOGLE_OAUTH2_SCOPES = process.env.NEXT_PUBLIC_GOOGLE_OAUTH2_SCOPES;
export const NOODLE_CREATOR_ID = process.env.NEXT_PUBLIC_NOODLE_CREATOR_ID || '';
export const NOODLE_CREATOR_SLUG = process.env.NEXT_PUBLIC_NOODLE_CREATOR_SLUG || '';
export const NOODLE_CREATOR_PERSON_ID = process.env.NEXT_PUBLIC_NOODLE_CREATOR_PERSON_ID || '';
export const NOODLE_AGENCY_ID = process.env.NEXT_PUBLIC_NOODLE_AGENCY_ID || '';
export const YOUR_AI_CREATOR_PERSON_ID = process.env.NEXT_PUBLIC_YOUR_AI_CREATOR_PERSON_ID || '';
export const YOUR_AI_CREATOR_SLUG = process.env.NEXT_PUBLIC_YOUR_AI_CREATOR_SLUG || '';
export const SOCKET_SERVICE_URL = process.env.NEXT_PUBLIC_SOCKET_SERVICE_URL || '';
export const CONFIDO_HOSTED_FIELDS_URL = process.env.NEXT_PUBLIC_CONFIDO_HOSTED_FIELDS_URL || '';
export const CONFIDO_URL = process.env.NEXT_PUBLIC_CONFIDO_URL || '';
// @todo, move to server config, but this requires secrets getting setup and don't need to bother with that for these.
export const PAGES_TO_FETCH_ON_INITIALIZE = (process.env.NEXT_PUBLIC_PAGES_TO_FETCH_ON_INITIALIZE || '').split(',').filter(s => Boolean(s));
export const VAPID_KEY = process.env.NEXT_PUBLIC_VAPID_KEY || '';
export const CANNY_COMPANY_ID = process.env.NEXT_PUBLIC_CANNY_COMPANY_ID || '';
export const FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED = process.env.NEXT_PUBLIC_FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED === 'true';
export const ENABLE_WEB_VITALS = process.env.NEXT_PUBLIC_ENABLE_WEB_VITALS === 'true';
export const NOODLE_CONSULTATION_PRODUCT_SLUG = process.env.NEXT_PUBLIC_NOODLE_CONSULTATION_PRODUCT_SLUG || '';
export const INTEGRATIONS_BACKEND_HOST = process.env.NEXT_PUBLIC_INTEGRATIONS_BACKEND_HOST;
export const QUESTIONNAIRES_BACKEND_HOST = process.env.NEXT_PUBLIC_QUESTIONNAIRES_BACKEND_HOST || 'http://localhost:3701';
export const DEBUG_EVALUATE_EXPRESSIONS = process.env.NEXT_PUBLIC_DEBUG_EVALUATE_EXPRESSIONS === 'true';
export const CREDIT_REPORT_KEY = process.env.NEXT_PUBLIC_CREDIT_REPORT_KEY || '';
