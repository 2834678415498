import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons']['PUT'];

type Input = ThisApi['body'];
type Output = ThisApi['response'];

const findOrCreatePerson = async ({ creatorSlug, identifier, name, phoneNumber }: Input): Promise<Output> => {
  const response = await noodleApiClient.put('/persons', { creatorSlug, identifier, name, phoneNumber });
  return response.data;
};

export default findOrCreatePerson;
