import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports/reports/noodle-products']['GET'];

const getNoodleProducts = async (): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get('/credit-reports/reports/noodle-products');
  return response.data;
};

export default getNoodleProducts;
