import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaire-templates/{creatorId}/{questionnaireTemplateSlug}/{statusOrVersion}']['GET'];
type Input = ThisApi['params'];

const getQuestionnaireTemplateBySlug = async ({ creatorId, questionnaireTemplateSlug, statusOrVersion }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>(
    '/questionnaire-templates/{creatorId}/{questionnaireTemplateSlug}/{statusOrVersion}',
    { creatorId, questionnaireTemplateSlug, statusOrVersion },
  );
  const data = await formsBackendClient.get(path);
  return data.data;
};

export default getQuestionnaireTemplateBySlug;
