import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports/{creditReportId}/permissions']['PUT'];
type Input = ThisApi['params'] & ThisApi['body'];

const updateCreditReportPermissions = async ({ creditReportId, userIds }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.put(`/credit-reports/${creditReportId}/permissions`, { userIds });
  return response.data;
};

export default updateCreditReportPermissions;
