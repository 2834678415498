import formsBackendClient, { ApiDefinition } from '@/tsClient/servers/noodleQuestionnaires';
import buildPath from '../utilities/buildPath';

type ThisApi = ApiDefinition['/questionnaires/{questionnaireId}/template']['GET'];
type Input = ThisApi['params'];

const getQuestionnaireTemplateOfQuestionnaire = async ({ questionnaireId }: Input): Promise<ThisApi['response']> => {
  const path = buildPath<ThisApi>('/questionnaires/{questionnaireId}/template', { questionnaireId });
  const data = await formsBackendClient.get(path);
  return data.data;
};

export default getQuestionnaireTemplateOfQuestionnaire;
