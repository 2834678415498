/* Manually copying stuff from noodle-api/src/typings/graphql-models, which is auto generate, nothing in here should be created by hand */
/*
 * Things to change on copy
 *   Scalars.DateTime and .Date should be string, not any.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: string; // changed from the any from codegen to reflect stringify that happens in express
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
  DateTime: string; // changed from the any from codegen to reflect stringify that happens in express
  Hex: any;
  /** Raw JSON value */
  Json: any;
  /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  /** Slate-compatible RichText AST */
  RichTextAST: any;
};

export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Dif = 'DIF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Svc = 'SVC',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usdc = 'USDC',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW'
}

export enum Interval {
  Day = 'DAY',
  Month = 'MONTH',
  Sixmonths = 'SIXMONTHS',
  Threemonths = 'THREEMONTHS',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum StripePriceUsageType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING'
}

// @deprecated use ApiModels instead
export enum NoodleProductTypes {
  Broadcast = 'Broadcast',
  Byte = 'Byte',
  Charge = 'Charge',
  Handbook = 'Handbook',
  InPersonSession = 'InPersonSession',
  Lite = 'Lite',
  Noodle = 'Noodle',
  NoodleSaaS = 'NoodleSaaS',
  OnlineSession = 'OnlineSession',
  ProductGroup = 'ProductGroup',
  Response = 'Response',
  Subscription = 'Subscription',
  Consultation = 'Consultation',
  // This was added to noodle-frontend, but isn't a real type. Is only there for the CreateServiceForm
  // Need a better way to handle
  Collection = 'Collection',
}

export type RichText = {
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED'
}

export enum StripeAccountType {
  Connect = 'Connect',
  Express = 'Express',
  NoodleMor = 'NoodleMOR'
}

export enum ProductState {
  Free = 'free',
  NotForSale = 'not_for_sale',
  OnSale = 'on_sale'
}

export type ExtendedProductType = {
  id: string;
  noodleProductType?: NoodleProductTypes | null;
  title?: string | null;
};

/** Status for subscription  at Person Subscription */
export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'Incomplete_expired',
  PastDue = 'Past_due',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid'
}

export enum ContentLibraryTypes {
  AppleMusic = 'AppleMusic',
  Facebook = 'Facebook',
  Deezer = 'Deezer',
  Files = 'Files',
  Instagram = 'Instagram',
  Music = 'Music',
  Spotify = 'Spotify',
  Tickets = 'Tickets',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Videos = 'Videos',
  Vimeo = 'Vimeo',
  YouTube = 'YouTube',
  Other = 'Other',
}

export const heroLinksAvailableTypes = [
  ContentLibraryTypes.Other,
  ContentLibraryTypes.Spotify,
  ContentLibraryTypes.AppleMusic,
  ContentLibraryTypes.Deezer,
  ContentLibraryTypes.YouTube,
  ContentLibraryTypes.Facebook,
  ContentLibraryTypes.Instagram,
  ContentLibraryTypes.Music,
  ContentLibraryTypes.Tickets,
  ContentLibraryTypes.TikTok,
];

export type Rgba = {
  __typename?: 'RGBA';
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

export type Color = {
  __typename?: 'Color';
  css?: Scalars['String'];
  hex: Scalars['Hex'];
  rgba?: Rgba;
};

/*
 * These are just a partial copy from noodle-api graphql-models, should probably just copy over the whole thing.
 * Feel free to add to these if needed, but base it on the noodle-api/src/typings/graphql-models.ts.
 * In general don't put nested objects in here because we don't want to, eg, Pick<Creator, 'person'> because we probably don't want the full person.
 */
export type Asset = {
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  youTubeLink?: Maybe<Scalars['String']>;
  vimeoUri?: Maybe<Scalars['String']>;
  loomEmbedUrl?: Maybe<Scalars['String']>;
};

export type Tag = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum CreatorTierType {
  Enterprise = 'Enterprise',
  Free = 'Free',
  Growth = 'Growth',
  Scale = 'Scale',
  Superstar = 'Superstar'
}

export type CreatorTier = Node & {
  __typename?: 'CreatorTier';
  applicationFeeCharge: Scalars['Int'];
  applicationFeeHandbook: Scalars['Int'];
  applicationFeeInPersonSession: Scalars['Int'];
  applicationFeeLite: Scalars['Int'];
  applicationFeeOnlineSession: Scalars['Int'];
  applicationFeeProductGroup: Scalars['Int'];
  applicationFeeResponse: Scalars['Int'];
  applicationFeeSubscription: Scalars['Int'];
  broadcastsPerMonthLimit?: Maybe<Scalars['Int']>;
  bytesLimit?: Maybe<Scalars['Int']>;
  canCreateCharge: Scalars['Boolean'];
  canCreateHandbook: Scalars['Boolean'];
  canCreateInPersonSession: Scalars['Boolean'];
  canCreateLite: Scalars['Boolean'];
  canCreateOnlineSession: Scalars['Boolean'];
  canCreateProductGroup: Scalars['Boolean'];
  canCreateResponse: Scalars['Boolean'];
  canCreateSubscription: Scalars['Boolean'];
  chatsLimit?: Maybe<Scalars['Int']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  creatorTierType: CreatorTierType;
  /** Get the document in other stages */
  documentInStages: Array<CreatorTier>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Name used internally to noodle, not to be displayed anywhere to the creator */
  internalName?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  leadsLimit?: Maybe<Scalars['Int']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
};

export enum FollowupCadenceUnits {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Weeks = 'weeks'
}

export type Creator = {
  routableCompanyId?: Maybe<Scalars['String']>;
  oneLiner?: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  confidoFirmId?: Maybe<Scalars['String']>;
  confidoFirmToken?: Maybe<Scalars['String']>;
  confidoStatus?: Maybe<Scalars['String']>;
  creatorDomain?: Maybe<Scalars['String']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  creatorSupportEmail?: Maybe<Scalars['String']>;
  facebookFollowers?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagramFollowers?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  isAiEnabled: Scalars['Boolean'];
  isNoodleStar?: Maybe<Scalars['Boolean']>;
  lastViewedDashboardAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  noodlePhoneNumber?: Maybe<Scalars['String']>;
  notifyOnEveryCustomerMessage?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['String']>;
  primaryColour?: Maybe<Color>;
  slug: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  stripeAccountType?: Maybe<StripeAccountType>;
  stripe_account_id?: Maybe<Scalars['String']>;
  successPaymentRedirectUrl?: Maybe<Scalars['String']>;
  tikTokUrl?: Maybe<Scalars['String']>;
  tiktokFollowers?: Maybe<Scalars['String']>;
  twitterFollowers?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  youtubeFollowers?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
  businessCategory?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  defaultCurrency?: Maybe<Scalars['String']>;
  logoForBranding?: Maybe<Pick<Asset, 'url'>>;
  utmCampaignId?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  hasFinishedOnboarding?: Maybe<Scalars['Boolean']>;
  isBuyEnabled: Scalars['Boolean'];
  followupCadence?: Maybe<Scalars['Int']>;
  followupCadenceUnits?: Maybe<FollowupCadenceUnits>;
  webUrl?: Maybe<Scalars['String']>;
};

export type HandbookStep = {
  id: Scalars['ID'];
  repetitionAmount?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
};

export type Price = {
  bookingCalendarLink?: Maybe<Scalars['String']>;
  confidoBankAccountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['ID'];
  membersCount?: number;
  isAchPaymentEnabled?: InputMaybe<Scalars['Boolean']>;
  isDebitCardPaymentEnabled?: InputMaybe<Scalars['Boolean']>;
  isCardPaymentEnabled?: InputMaybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isMostPopular?: Maybe<Scalars['Boolean']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  numBookings?: Maybe<Scalars['Int']>;
  originalPrice?: Maybe<Scalars['Float']>;
  passProcessingFeesToCustomer?: Maybe<Scalars['Boolean']>;
  passStripeFeesToCustomer?: InputMaybe<Scalars['Boolean']>;
  price: Scalars['Float'];
  priceDescription?: Maybe<RichText>;
  priceSubDescription?: Maybe<Scalars['String']>;
  priceTitle?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Interval>;
  stripePriceId?: Maybe<Scalars['String']>;
  tierId?: Scalars['String'] | null;
  trialPeriodDays?: Maybe<Scalars['Int']>;
  usageType: StripePriceUsageType;
  secondaryStripeAccountId?: Maybe<Scalars['String']>;
  sessionDuration?: Maybe<Scalars['Int']>;
  sessionInterval?: Maybe<Scalars['Int']>;
  sessionTime?: Maybe<Scalars['String']>;
};

export enum MediaStatus {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING',
  Removed = 'REMOVED'
}

export enum MessageStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Published = 'PUBLISHED',
  Error = 'ERROR',
}

export type Media = {
  id: Scalars['ID'];
  fileSize?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  mediaStatus: MediaStatus;
  vimeoThumbnailUrl?: Maybe<Scalars['String']>;
  vimeoUri?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  s3Id?: Maybe<Scalars['String']>;
  s3OriginId?: Maybe<Scalars['String']>;
  s3Region?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  transloaditS3Id?: Maybe<Scalars['String']>;
};

export type ProductType = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  noodleProductType?: Maybe<NoodleProductTypes>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  sendGridContactListId?: Maybe<Scalars['String']>;
  contactListTemplateEmailId?: Maybe<Scalars['String']>;
  upsellChatUrl?: Maybe<Scalars['String']>;
  upsellChatText?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<Asset>;
};

export type Course = Node & {
  description?: Maybe<RichText>;
  id: Scalars['ID'];
  productTypes: Array<ProductType>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Product = {
  address?: string | null;
  canUpsell?: Maybe<Scalars['Boolean']>;
  confidoBankAccountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultSchedulingPersonId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  includeHandbooks?: Maybe<Scalars['Boolean']>;
  includesBroadcasts?: Maybe<Scalars['Boolean']>;
  includesFreeMembership?: Maybe<Scalars['Boolean']>;
  initiateCaseRank?: Maybe<Scalars['Int']>;
  isActive: boolean;
  isTierBasedPermissioningEnabled: Scalars['Boolean'];
  lastUpdate: Scalars['DateTime'];
  noodleApplicationFlatFee?: Maybe<Scalars['Float']>;
  responseWelcomeMessage?: { html: string } | null;
  secondaryStripeAccountId?: Maybe<Scalars['String']>;
  sendgridListId?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  showOnOneLink?: boolean;
  slug: Scalars['String'];
  state: ProductState;
  stripeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
  pageSize: number;
};

export type ProductQuestion = {
  id: Scalars['ID'];
  title: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  question: Scalars['String'];
};

export type ProductReview = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  review?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['Int']>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK'
}

export type User = Node & {
  __typename?: 'User';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** Get the document in other stages */
  documentInStages: Array<User>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind;
  /** The username */
  name: Scalars['String'];
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
};

export type ProductCollectionDescriptionRichText = {
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  markdown: Scalars['String'];
  raw: Scalars['RichTextAST'];
  text: Scalars['String'];
};

export type ProductCollection = Node & {
  categoryDescription?: Maybe<Scalars['String']>;
  collectionDescription?: Maybe<Scalars['String']>;
  collectionTitle?: Maybe<Scalars['String']>;
  description?: Maybe<ProductCollectionDescriptionRichText>;
  id: Scalars['ID'];
  introVideo?: Maybe<Asset>;
  slug: Scalars['String'];
};

export type CreatorSocialMedia = {
  githubAvatarUrl?: Maybe<Scalars['String']>;
  githubEmail?: Maybe<Scalars['String']>;
  githubId?: Maybe<Scalars['Int']>;
  githubName?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagramBio?: Maybe<Scalars['String']>;
  instagramFollowerCount?: Maybe<Scalars['Int']>;
  instagramFullName?: Maybe<Scalars['String']>;
  instagramId?: Maybe<Scalars['String']>;
  instagramProfilePhotoUrl?: Maybe<Scalars['String']>;
  instagramUsername?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinName?: Maybe<Scalars['String']>;
  tiktokFollowerCount?: Maybe<Scalars['Int']>;
  tiktokUsername?: Maybe<Scalars['String']>;
  twitterFollowersCount?: Maybe<Scalars['Int']>;
  twitterId?: Maybe<Scalars['String']>;
  twitterName?: Maybe<Scalars['String']>;
  twitterProfileImageUrl?: Maybe<Scalars['String']>;
  twitterUsername?: Maybe<Scalars['String']>;
  youtubeChannelDescription?: Maybe<Scalars['String']>;
  youtubeChannelId?: Maybe<Scalars['String']>;
  youtubeChannelName?: Maybe<Scalars['String']>;
  youtubeChannelSubscriberCount?: Maybe<Scalars['Int']>;
  youtubeChannelVideoCount?: Maybe<Scalars['Int']>;
  youtubeChannelViewCount?: Maybe<Scalars['Int']>;
};

export type CreatorSocialMediaUpdateInput = {
  githubAvatarUrl?: InputMaybe<Scalars['String']>;
  githubEmail?: InputMaybe<Scalars['String']>;
  githubId?: InputMaybe<Scalars['Int']>;
  githubName?: InputMaybe<Scalars['String']>;
  githubUsername?: InputMaybe<Scalars['String']>;
  instagramBio?: InputMaybe<Scalars['String']>;
  instagramFollowerCount?: InputMaybe<Scalars['Int']>;
  instagramFullName?: InputMaybe<Scalars['String']>;
  instagramId?: InputMaybe<Scalars['String']>;
  instagramProfilePhotoUrl?: InputMaybe<Scalars['String']>;
  instagramUsername?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  linkedinName?: InputMaybe<Scalars['String']>;
  tiktokFollowerCount?: InputMaybe<Scalars['Int']>;
  tiktokUsername?: InputMaybe<Scalars['String']>;
  twitterFollowersCount?: InputMaybe<Scalars['Int']>;
  twitterId?: InputMaybe<Scalars['String']>;
  twitterName?: InputMaybe<Scalars['String']>;
  twitterProfileImageUrl?: InputMaybe<Scalars['String']>;
  twitterUsername?: InputMaybe<Scalars['String']>;
  youtubeChannelDescription?: InputMaybe<Scalars['String']>;
  youtubeChannelId?: InputMaybe<Scalars['String']>;
  youtubeChannelName?: InputMaybe<Scalars['String']>;
  youtubeChannelSubscriberCount?: InputMaybe<Scalars['Int']>;
  youtubeChannelVideoCount?: InputMaybe<Scalars['Int']>;
  youtubeChannelViewCount?: InputMaybe<Scalars['Int']>;
};

export type LandingIconWithNumber = {
  __typename?: 'LandingIconWithNumber';
  color: Color;
  /** Pick the icon name here: https://phosphoricons.com */
  icon: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['Int'];
  /** System stage field */
  stage: Stage;
};

export type LandingPriceFeature = {
  __typename?: 'LandingPriceFeature';
  color: Color;
  /** Pick the icon name here: https://phosphoricons.com */
  icon: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  label: Scalars['String'];
  /** System stage field */
  stage: Stage;
};

export type LandingCreator = {
  __typename?: 'LandingCreator';
  description: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  link: Scalars['String'];
  name: Scalars['String'];
  socials: Array<LandingIconWithNumber>;
  /** System stage field */
  stage: Stage;
};

export type LandingPrice = {
  __typename?: 'LandingPrice';
  additionalCost?: Maybe<Scalars['String']>;
  color?: Maybe<Color>;
  features: Array<LandingPriceFeature>;
  featuresTitle?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  price: Scalars['String'];
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
};

export type LandingFeature = {
  __typename?: 'LandingFeature';
  color: Color;
  description: Scalars['String'];
  /** Pick the icon name here: https://phosphoricons.com */
  icon: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
};

export type LandingIconWithString = {
  __typename?: 'LandingIconWithString';
  color: Color;
  /** Pick the icon name here: https://phosphoricons.com */
  icon: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  labelSecondary?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

export type PriceCreateInput = {
  bookingCalendarLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  isActive?: Maybe<Scalars['Boolean']>;
  isMostPopular?: Maybe<Scalars['Boolean']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  numBookings?: InputMaybe<Scalars['Int']>;
  originalPrice?: Maybe<Scalars['Float']>;
  passStripeFeesToCustomer?: Maybe<Scalars['Boolean']>;
  price: Scalars['Float'];
  priceDescription?:Maybe<Scalars['RichTextAST']>;
  priceSubDescription?:Maybe<Scalars['String']>;
  priceTitle?: Maybe<Scalars['String']>;
  recurringInterval?: Maybe<Interval>;
  stripePriceId?: Maybe<Scalars['String']>;
  sessionTime?: InputMaybe<Scalars['String']>;
  sessionDuration?: InputMaybe<Scalars['Int']>;
  sessionInterval?: InputMaybe<Scalars['Int']>;
  tierId?: Maybe<Scalars['String']>;
  trialPeriodDays?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usageType: StripePriceUsageType;
};

export type LandingProduct = {
  __typename?: 'LandingProduct';
  description?: Maybe<Scalars['String']>;
  heading: RichText;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  productLabel?: Maybe<LandingIconWithString>;
  /** System stage field */
  stage: Stage;
};

export type LandingSuperstarListItem = {
  __typename?: 'LandingSuperstarListItem';
  emoji: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  /** System stage field */
  stage: Stage;
};

export type LandingSuperstar = {
  __typename?: 'LandingSuperstar';
  ctaLabel: Scalars['String'];
  description?: Maybe<RichText>;
  heading?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  list: Array<LandingSuperstarListItem>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
};

export type LandingLabelWithDescription = {
  __typename?: 'LandingLabelWithDescription';
  description?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  /** System stage field */
  stage: Stage;
};

export type LandingBestOfAll = {
  __typename?: 'LandingBestOfAll';
  columnItems: Array<LandingIconWithString>;
  heading?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  quadrantItems: Array<LandingLabelWithDescription>;
  /** System stage field */
  stage: Stage;
};

export type Landing = Node & {
  __typename?: 'Landing';
  aboutNoodle?: Maybe<Scalars['Json']>;
  bestOfAll?: Maybe<LandingBestOfAll>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  creators: Array<LandingCreator>;
  creatorsHeading?: Maybe<RichText>;
  /** Get the document in other stages */
  documentInStages: Array<Landing>;
  features: Array<LandingFeature>;
  hero: Scalars['Json'];
  heroAnimation: Scalars['Json'];
  /** List of Landing versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  pricingCards: Array<LandingPrice>;
  pricingHeading?: Maybe<Scalars['Json']>;
  products: Array<LandingProduct>;
  productsHeading?: Maybe<RichText>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  showSupportedPlatforms: Scalars['Boolean'];
  slug: Scalars['String'];
  /** System stage field */
  stage: Stage;
  superstar?: Maybe<LandingSuperstar>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type SubscriptionModel = Node & {
  __typename?: 'SubscriptionModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  handbookAlias?: Maybe<Scalars['String']>;
};

export type Faq = Node & {
  content?: Maybe<RichText>;
  id: Scalars['ID'];
};

/** Saving information about paid subscriptions */
export type PersonSubscription = Node & {
  __typename?: 'PersonSubscription';
  canceledAt?: Maybe<Scalars['DateTime']>;
  conversationId?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  currency: Currency;
  customerId: Scalars['String'];
  /** Get the document in other stages */
  documentInStages: Array<PersonSubscription>;
  endedAt?: Maybe<Scalars['DateTime']>;
  hasViewedConfirmationPage?: Maybe<Scalars['Boolean']>;
  /** List of PersonSubscription versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  isFulfilled?: Maybe<Scalars['Boolean']>;
  lastBillingDate?: Maybe<Scalars['DateTime']>;
  localCurrency?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  personId?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** System stage field */
  stage: Stage;
  startedAt: Scalars['DateTime'];
  /** Saving id from stripe */
  stripeOrderId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type PersonProduct = Node & {
  __typename?: 'PersonProduct';
  conversationId?: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  currency?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  /** Get the document in other stages */
  documentInStages: Array<PersonProduct>;
  hasViewedConfirmationPage?: Maybe<Scalars['Boolean']>;
  /** List of PersonProduct versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  isFulfilled?: Maybe<Scalars['Boolean']>;
  localCurrency?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
};

export type Testimonial = Node & {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Asset>;
  position?: Maybe<Scalars['String']>;
  productTypes: Array<ProductType>;
  products: Array<Product>;
  quote?: Maybe<Scalars['String']>;
  video?: Maybe<Asset>;
};
