import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports/{creditReportId}/purchase']['POST'];

type Input = ThisApi['params'] & ThisApi['body'];

const purchaseCreditReport = async ({ creditReportId, ...body }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.post(`/credit-reports/${creditReportId}/purchase`, body);
  return response.data;
};

export default purchaseCreditReport;
