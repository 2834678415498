import translateTexts from '@/tsClient/translations/translateTexts';
import { logError } from '../ErrorTracking';

const getTextNodesIn = (node: Node, excludeElements: string[] = [], excludeAttributes: { [attr: string]: string } = {}): Node[] => {
  const textNodes: Node[] = [];

  const hasExcludedAttributes = (element: HTMLElement, attributes: { [attr: string]: string }): boolean =>
    Object.entries(attributes).some(([attr, value]) => element.getAttribute(attr) === value);

  if (node.nodeType === Node.TEXT_NODE) {
    textNodes.push(node);
  } else if (
    node.nodeType === Node.ELEMENT_NODE
    && !excludeElements.includes((node as HTMLElement).tagName.toLowerCase())
    && !hasExcludedAttributes(node as HTMLElement, excludeAttributes)
  ) {
    for (let childNode = node.firstChild; childNode; childNode = childNode.nextSibling) {
      textNodes.push(...getTextNodesIn(childNode, excludeElements, excludeAttributes));
    }
  }
  return textNodes;
};

const isNumeric = (text: string): boolean => /^[\d\s.,:;!?-]+$/.test(text.trim());

const isUrl = (text: string): boolean => {
  const urlPattern = /(https?:\/\/(?:www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,})(?:[/?#][^\s]*)?/;
  return urlPattern.test(text);
};

const handleTranslate = async (targetLanguage: string): Promise<void> => {
  const excludeElements = ['style', 'script'];
  const excludeAttributes = { 'data-translate': 'false' };

  const textNodes = getTextNodesIn(document.body, excludeElements, excludeAttributes);

  const textContents = textNodes.map((node, index) => ({
    index,
    text: node.nodeValue ?? '',
  }));

  const textsToTranslate = textContents
    .filter(item => item.text.trim() && !isNumeric(item.text) && !isUrl(item.text))
    .map(item => item.text);

  if (textsToTranslate.length === 0) {
    return;
  }

  const translateBatch = async (texts: string[]): Promise<string[]> => {
    try {
      const response = await translateTexts({
        target: targetLanguage,
        texts,
      });
      return response.translations || texts;
    } catch (error) {
      logError(error);
      return texts;
    }
  };

  const translations = await translateBatch(textsToTranslate);

  let translationIndex = 0;
  textContents.forEach(({ index, text }) => {
    const trimmedText = text.trim();
    if (trimmedText && !isNumeric(text) && !isUrl(text)) {
      const leadingSpaces = text.match(/^\s*/)?.[0];
      const trailingSpaces = text.match(/\s*$/)?.[0];
      textNodes[index].nodeValue = leadingSpaces + translations[translationIndex] + trailingSpaces;
      translationIndex += 1;
    }
  });
};

export default handleTranslate;
