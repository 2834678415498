import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/credit-reports/{creditReportId}/response']['GET'];
type Input = ThisApi['params'];

const getCreditReportResponse = async ({ creditReportId }: Input): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/credit-reports/${creditReportId}/response`);
  return response.data;
};

export default getCreditReportResponse;
